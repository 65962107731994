.candle {
	position: relative;
	display: block;
	width: 20px;
	height: 100px;
	background-color: blue;
	color: blue;
	
}

.candle .stick {
	position: absolute;
	display: block;
	width: 1px;
	height: 100%;
	top: 0;
	left: 10px;
	background-color: red;
}

.candle .body {
	position: absolute;
	display: block;
	width: 100%;
	height: 50%;
	top: 0;
	left: 0;
	background-color: red;
}

.candle p {
	position: relative;
	color: white;
}
